import React from 'react';
import { styled, theme } from '../../Theme';
import { StyleOrStyleArray } from '@glitz/type';
import useMedia from '../../Shared/Hooks/useMedia';
import Image from './Image';
import { StyledProps } from '@glitz/react';

type PropType = StyledProps & {
  title?: string;
  alt?: string;
  ratio?: string;
  src?: string;
  children?: any;
};

function ImageRatio({
  src,
  alt = '',
  title,
  ratio,
  children,
  compose,
}: PropType) {
  function RatioSelector() {
    if (ratio) {
      return ratio;
    } else if (!ratio && isMobile) {
      return theme.ratioMobile;
    } else {
      return theme.ratioDesktop;
    }
  }

  const isMobile = useMedia(theme.mediaQuery.mediaMaxMedium);
  const imageAreaBackgroundStyle: StyleOrStyleArray = {
    paddingBottom: RatioSelector(),
  };

  return (
    <ImageContainer
      css={
        compose ? compose(imageAreaBackgroundStyle) : imageAreaBackgroundStyle
      }
    >
      {children ? (
        children
      ) : (
        <ImageHolder alt={alt} title={title} src={src ? src : ''} />
      )}
    </ImageContainer>
  );
}

export default styled(ImageRatio);

const ImageContainer = styled.div({
  position: 'relative',
  backgroundSize: 'cover',
});

const ImageHolder = styled(Image, {
  position: 'absolute',
  objectFit: 'cover',
  width: '100%',
  height: '100%',
});
