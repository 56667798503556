import React, { useState, useRef } from 'react';
import { RegionButton } from '../Shared/Button/Button';
import HeroModule from '../Shared/Hero/HeroModule';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { styled, theme } from '../Theme';
import LanguageSelectorModel from './Models/LanguageSelectorModel.interface';
import { media, pseudo } from '@glitz/core';
import Breadcrumbs from '../Shared/Breadcrumb/Breadcrumb';
import ContentArea from '../Shared/ContentArea/ContentArea';
import { useHeaderData } from '../Shared/Models/Headers/Header';

function LanguageSelectorPage() {
  const {
    regions,
    teaserImage,
    teaserText,
    teaserHeader,
    inEditMode,
    teaserLinks,
  } = useCurrentPage<LanguageSelectorModel>();
  const {
    translations: {
      'languageSelectorPage/findYourRegion': findYourRegionLabel,
    },
  } = useAppSettingsData();
  const { headerHeight } = useHeaderData();

  const [activeRegion, setActiveRegion] = useState<string>('');
  const inputRef = useRef<HTMLDivElement[]>([]);

  const regionButtonClick = (header: string) => {
    setActiveRegion(header);
  };

  const scrollToRegionHandler = (index: number) => {
    window.scrollTo({
      top: inputRef.current[index].offsetTop - headerHeight,
      behavior: 'smooth',
    });
  };

  return (
    <Root>
      <BreadCrumbContainer>
        <Breadcrumbs />
      </BreadCrumbContainer>
      <HeroModule
        image={teaserImage}
        inEditMode={inEditMode}
        header={teaserHeader}
        summary={teaserText}
        links={teaserLinks.map(link => ({ ...link, fullPageReload: true }))}
      />
      <LowerContainer>
        <PageContainer>
          <Title>{findYourRegionLabel} </Title>
          <ButtonContainer>
            {regions.map((region, index: number) => (
              <Button
                onClick={() => {
                  region.header && regionButtonClick(region.header);
                  scrollToRegionHandler(index);
                }}
                css={region.listCountries ? {} : { display: 'none' }}
                key={region.header + index.toString()}
                isActive={activeRegion === region.header}
              >
                <ButtonTextWrapper>{region.header}</ButtonTextWrapper>
              </Button>
            ))}
          </ButtonContainer>
          <styled.Div>
            {regions.map((region, index: number) => (
              <RegionItem
                key={region.header + (index + 50).toString()}
                css={region.listCountries ? {} : { display: 'none' }}
              >
                <RegionHeader
                  ref={el => {
                    return el && (inputRef.current[index] = el);
                  }}
                  css={{ top: headerHeight }}
                >
                  {region.header}
                  <Divider />
                </RegionHeader>

                <CountyList>
                  <ContentArea childItems={region.listCountries} />
                </CountyList>
              </RegionItem>
            ))}
          </styled.Div>
        </PageContainer>
      </LowerContainer>
    </Root>
  );
}

const LowerContainer = styled.div({
  backgroundColor: theme.white,
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: { y: theme.spacing(16) },
  }),
});

const BreadCrumbContainer = styled.div({
  ...media(theme.mediaQuery.mediaMaxLarge, {
    margin: { x: theme.spacing(2) },
  }),
});

const RegionItem = styled.div({});

const CountyList = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
});

const Title = styled.h2({
  font: { size: theme.spacing(8), weight: theme.fontWeight.bold },
  lineHeight: theme.lineHeight.tight,
  letterSpacing: theme.letterSpacing.heading,
  color: theme.black,
  ...media(theme.mediaQuery.mediaMinLarge, {
    fontSize: theme.eta,
  }),
  marginBottom: theme.spacing(8),
});

const PageContainer = styled.div({
  width: '100%',
  maxWidth: theme.rowSectionContentMaxWidth,
  margin: {
    x: 'auto',
  },
  padding: {
    y: theme.none,
  },
  ...media(theme.mediaQuery.mediaMaxLarge, {
    padding: { xy: theme.spacing(4) },
  }),
});

const RegionHeader = styled.h2({
  font: { size: theme.spacing(8), weight: theme.fontWeight.bold },
  lineHeight: theme.lineHeight.tight,
  letterSpacing: theme.letterSpacing.heading,
  backgroundColor: theme.white,
  color: theme.black,
  ...media(theme.mediaQuery.mediaMinLarge, {
    fontSize: theme.epsilon,
  }),
  padding: { top: theme.spacing(8), bottom: theme.spacing(2) },
  position: 'sticky',
});

const Root = styled.div({
  color: theme.black,
  backgroundColor: theme.lightAqua,
});

const ButtonContainer = styled.div({
  marginBottom: theme.spacing(2),
  ...media(theme.mediaQuery.mediaMaxLarge, {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    marginBottom: theme.spacing(8),
  }),
});

const Button = styled(RegionButton, {
  padding: { x: theme.spacing(4), y: theme.spacing(2) },
  height: 'auto',
  font: { size: theme.gamma },
  letterSpacing: theme.letterSpacing.normalWide,
  borderRadius: theme.spacing(8),
  lineHeight: theme.spacing(4),
  color: theme.primary,
  border: {
    xy: {
      style: 'solid',
      width: theme.spacing(0.5),
      color: theme.blueDark,
    },
  },
  margin: { xy: theme.spacing(1) },
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { xy: theme.none },
    ...pseudo(':not(:first-child)', {
      margin: {
        left: theme.spacing(4),
      },
    }),
  }),
});

const Divider = styled.div({
  width: theme.spacing(16),
  height: theme.spacing(0.5),
  backgroundColor: theme.blueDark,
  margin: { y: theme.spacing(4) },
});

const ButtonTextWrapper = styled.p({
  fontSize: theme.beta,
  fontWeight: theme.fontWeight.bold,
});

export default LanguageSelectorPage;
