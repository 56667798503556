import React from 'react';
import { applyEditModeAttr } from '../Common/Helpers';
import { styled, theme } from '../../Theme';
import { media } from '@glitz/core';
import useMedia from '../../Shared/Hooks/useMedia';
import IImageModel from '../../Assets/Models/ImageModel.interface';
import ILink from '../Models/Link.interface';
import ImageRatio from '../Image/ImageRatio';
import Grid from '../Grid/Grid';
import { GridSize } from '../../Enums/GridSize.enum';
import { spacing } from '../../Theme/spacing';
import IconLink from '../../Shared/IconLink/IconLink';
import { LinkType } from '../../Enums/LinkType.enum';

type HeroModuleType = {
  image: IImageModel;
  inEditMode: boolean;
  header?: string;
  summary?: string;
  links?: ILink[];
};

function HeroModule({
  header,
  summary,
  inEditMode,
  links,
  image,
}: HeroModuleType) {
  const isMobile = useMedia(theme.mediaQuery.mediaMaxLarge);

  return (
    <Root>
      <BlockWrapper>
        <Grid type={isMobile ? GridSize.Zero : GridSize.Eight}>
          {image && image?.src && (
            <ImageRatioWrapper>
              <ImageRatio src={image ? image.src : ''} />
            </ImageRatioWrapper>
          )}
          <SummaryContainer>
            <Header {...applyEditModeAttr(inEditMode && 'TeaserHeader')}>
              {header}
            </Header>
            <TextWrapper>
              <Summary {...applyEditModeAttr(inEditMode && 'TeaserText')}>
                {summary}
              </Summary>
              {links && (
                <LinkContainer>
                  {links.length > 0 &&
                    links.map((link, index) => (
                      <StyledIconLink
                        key={link.id + index.toString()}
                        href={link.href}
                        type={LinkType.Arrow}
                        fullPageReload={link.fullPageReload}
                      >
                        {link.text}
                      </StyledIconLink>
                    ))}
                </LinkContainer>
              )}
            </TextWrapper>
          </SummaryContainer>
        </Grid>
      </BlockWrapper>
    </Root>
  );
}

const Root = styled.div({
  display: 'flex',
  width: '100%',
  height: '100%',
  position: 'relative',
  justifyContent: 'center',
  backgroundColor: theme.lightAqua,
});

const ImageRatioWrapper = styled.div({
  padding: { xy: theme.spacing(4) },
  paddingTop: theme.none,
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: { xy: 0 },
    gridColumnStart: '2',
    gridColumnEnd: '5',
  }),
});

const BlockWrapper = styled.div({
  margin: { top: spacing(1), bottom: spacing(2) },
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { top: spacing(2), bottom: spacing(16) },
  }),
});

const TextWrapper = styled.div({
  position: 'relative',
  overflowY: 'hidden',
});

const StyledIconLink = styled(IconLink, {
  marginTop: theme.spacing(1),
  marginLeft: 'auto',
});

const SummaryContainer = styled.div({
  marginTop: theme.spacing(13),
  margin: { xy: 'auto' },
  ...media(theme.mediaQuery.mediaMaxLarge, {
    marginTop: theme.spacing(0),
    padding: { xy: theme.spacing(4), top: theme.none },
  }),
  gridColumnStart: '5',
  gridColumnEnd: '8',
});

const Header = styled.h1({
  font: { size: theme.spacing(8), weight: theme.fontWeight.bold },
  lineHeight: theme.lineHeight.tight,
  letterSpacing: theme.letterSpacing.heading,
  color: theme.primary,
  marginBottom: theme.spacing(2),
  ...media(theme.mediaQuery.mediaMinLarge, {
    marginBottom: theme.none,
    fontSize: theme.eta,
  }),
});

const Summary = styled.p({
  font: { size: theme.gamma, weight: theme.fontWeight.lighter },
  lineHeight: theme.lineHeight.snug,
  letterSpacing: theme.letterSpacing.moreWide,
  color: theme.secondaryText,
  opacity: 0.9,
  ...media(theme.mediaQuery.mediaMinSmall, {
    fontSize: theme.delta,
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    fontSize: theme.gamma,
    marginTop: theme.spacing(4),
    lineHeight: theme.lineHeight.relaxed,
    letterSpacing: theme.letterSpacing.medium,
  }),
});

const LinkContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: { top: theme.spacing(3) },
});

export default HeroModule;
